<template>
    <div>
            <v-card elevation="2"
                    outlined
                    tile
                    class="py-5 px-5 my-2">
                <v-row>
                    <v-col class="col-md-10">
                        <v-text-field
                            label="Nombre categoría"
                            placeholder="agrega un nombre para la categoría"
                            v-model="category.tag"
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col class="col-md-2">
                        <v-btn
                            class="ma-2"
                            text
                            icon
                            color="indigo"
                            @click="changeSegment()"
                        >
                            <v-icon> mdi-cloud-upload</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                 <v-row>
                    <v-col>
                        <br>
                        <quill-editor
                                ref="myQuillEditor"
                                v-model="category.description"
                                :options="editorOption"
                        />
                        <!-- <v-textarea
                            outlined
                            name="input-7-4"
                            label="Descripción"
                            v-model="segment.description"
                        ></v-textarea> -->
                    </v-col>
                </v-row>
           
            </v-card>
         <v-row v-for="item in category.architecture_tag" :key="item">
            <v-col >
                <v-card
                    elevation="2"
                    outlined
                    tile
                    class="py-8 px-6"
                >
                <v-row>
                    <v-col class="col-md-5">
                        <h2>{{item.tag}}</h2>
                    </v-col>
                    <v-col class="col-md-5">
                        <v-text-field :id="'name'+item.id"
                            label="Nombre de la categoria"
                            placeholder="Placeholder"
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col class="col-md-2">
                        <v-btn
                            class="ma-2"
                            text
                            icon
                            color="indigo"
                            @click="categoriesUpdate(item.id)"
                        >
                            <v-icon> mdi-cloud-upload</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                 <v-row>
                     <v-col class="col-md-5">
                        <h2>{{item.description}}</h2>
                    </v-col>
                    <v-col class="col-md-7">
                        <v-textarea :id="'description'+item.id"
                            outlined
                            name="input-7-4"
                            label="Descripción de la categoria"
                        ></v-textarea>
                    </v-col>
                </v-row>
                </v-card>
            </v-col>
        </v-row>
        
    </div>
</template>
<script>
import {misMixins} from '../../../vue/mixinsAlert';
export default {
    data(){
        return{
            category:{
                tag:'',
                description:'',
                id:''
            },
            id : this.$route.params.id,
            editorOption: { 
            }
        }
    },

    mixins:[misMixins],

    methods:{
        getCategoryInfo(){
            
            this.$http.get('architecture_tags/'+this.id).then(response => {
                this.category=response.data
            }).catch(error => {
                this.ToastError(error.data.status +' '+error.data.error)
                // console.log('ERROR AL CONSULTAR LAS DIMENSIONES', error.data.exception)
            })
        },
        changeSegment(){
             this.$http.put('architecture_tags/'+this.id,{
                 data:{
                     attributes:{
                         tag:this.segment.tag,
                         description:this.segment.description
                     }
                 }
             },{headers:{"Authorization": 'Bearer '+this.getCrypto()}}).then(response => {
                this.segment=response.data

                this.LoadingClose();
                            
                    let me = this
                    me.$swal({
                        title: "Actualizado",
                        text: "categoría actualizada con éxito",
                        icon: 'success',
                        confirmButtonText: 'Aceptar',
                        allowOutsideClick: false,
                            }).then((result) => {
                                if (result.value) {
                                        me.$router.push({ name: 'Categories'});
                                    }
                        })

                    }).catch((error) => {
                        this.LoadingClose();
                        
                        this.ErrorAlert('Error al actualizar la categoría')
                    });
        },
        categoriesUpdate(id){
            const name = document.getElementById(`name`+id).value
            const description = document.getElementById(`description`+id).value
            this.$http.put('architecture_tags/'+id,{
                 data:{
                     attributes:{
                         tag:name,
                         description:description
                     }
                 }
             },{headers:{"Authorization": 'Bearer '+this.getCrypto()}}).then(response => {
                this.segment=response.data
                this.$router.push({ name: 'Categories'});
            }).catch(error => {
                //console.log('ERROR AL CONSULTAR LAS DIMENSIONES', error)
            })
        }
    },
    created(){
        this.getCategoryInfo()
    }
}
</script>
